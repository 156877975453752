<template>
  <div class="container-fluid">
    <div v-if="loaded">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h4 id="top">User Report Page</h4>
      </div>

      <div class="row card search-card">
        <div class="rainbow-background"></div>
        <p class="instructions">To see Reports select a User</p>
        <div class="col-md-12 row">
          <div class="col-md-3 align-left">
            <label for="coach" class="align-left">Trainee: </label>
            <v-select id="coach" :options="trainees" :clearable="false" :clearSearchOnSelect="false" :resetOnOptionsChange="true" label="full_name" v-model="selectedTrainee" placeholder="Select a Trainee"></v-select>
          </div>
        </div>
      </div>

      <br>

      <h3 class="mt-5" v-if="loadingText">
        Loading Reports...
      </h3>

      <div v-if="reportLoaded" class="card">
        <table class="card-table table">
          <thead>
          <tr>
            <th>Date</th>
            <th>Role of Trainee</th>
            <th>Region</th>
            <th>Market Area</th>
            <th>Coach</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(activity, index) in data">
            <template v-for="(day, day_index) in days">
              <tr v-if="activity.time_card_activity[day] !== null" :key="`${index}-${day_index}`" >
                <td v-bind:key="day_index">{{ formattedDate(activity.time_card_activity.time_card.week_start_date, days.indexOf(day) + 1) }}</td>
                <td>{{ activity.trainee_role ? activity.trainee_role.title : "N/A" }}</td>
                <td>{{ activity.user.region ? activity.user.region.name : "N/A" }}</td>
                <td>{{ activity.user.region ? activity.user.region.market_area.name : "N/A" }}</td>
                <td>{{ activity.time_card_activity.time_card.user.first_name + " " + activity.time_card_activity.time_card.user.last_name}}</td>
              </tr>
            </template>
          </template>
          <tr>
            <td>TOTAL</td>
            <td>{{ data_length }}</td>
          </tr>
          </tbody>
        </table>
      </div>

    </div>
    <div v-else>
      <loading-overlay></loading-overlay>
    </div>
    <button @click="scrollToTop" id="topButton" type="button" class="btn btn-success">TOP</button>
  </div>
</template>

<script>
import { TimeCardActivityService } from '@/common/services/time_card_activity.service';
import { UserService } from '@/common/services/user.service';
import { FilterService } from '@/common/services/filter.service';
import HelperMethods from '@/common/mixins/helper_methods';
import LoadingOverlay from '@/common/components/loading-overlay.vue';
import _ from 'lodash';

export default {
  name: 'UserReportsIndex',
  mixins: [HelperMethods],
  components: {
    LoadingOverlay,
  },
  data() {
    return {
      loaded: false,
      loadingText: false,
      reportLoaded: false,
      data: [],
      trainees: [],
      traineeId: null,
      selectedTrainee: null,
      data_length: 0,
      days: ['day_one', 'day_two', 'day_three', 'day_four', 'day_five', 'day_six', 'day_seven'],
    };
  },
  created() {
    this.getTrainees()
      .then((response) => {
        this.log('created', response);
        // this.trainees = response.data;
        this.loaded = true;
        return response.data;
      })
      .then((data) => {
        this.trainees = _.sortBy(data, ['first_name']);
      });
  },
  watch: {
    selectedTrainee(val) {
      this.log('watch selectedTrainee', val);
      this.traineeId = val.id;
      this.loadingText = true;
      this.reportLoaded = false;
      this.getTraineeReport();
    },
  },
  methods: {
    getTrainees() {
      this.filters = FilterService.defaultData();
      this.filters.role_eq = 'Trainee';
      const params = FilterService.build({ page: 1, per_page: 3000 }, this.filters);
      return UserService.query(params);
    },
    getTraineeReport() {
      this.data_length = 0;
      const params = {
        user_id: this.traineeId,
      };
      this.log('getTraineeReport params', params);
      TimeCardActivityService.userReport(params)
        .then((response) => {
          this.log('getTraineeReport => TimeCardActivityService.userReport', response);
          this.data = response.data;
          Object.keys(response.data).forEach((activity) => {
            Object.keys(this.days).forEach((day) => {
              if (response.data[activity].time_card_activity[this.days[day]] !== null) this.data_length += 1;
            });
          });
          this.loadingText = false;
          this.reportLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-select.single.searching:not(.open):not(.loading) input[type=search] {
    opacity: 1;
  }
  .align-left {
    text-align: left;
  }
  .search-card {
    padding-bottom: 30px;

    .instructions {
      padding: 10px;
      border: 1px solid #dee3e7;
      border-radius: 5px;
      margin: 15px auto 15px;
    }
  }

  #topButton {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    color: white;
    cursor: pointer;
    padding: 10px;
    border-radius: 15px;
    font-size: 15px;

    &:hover {
      background-color: #555;
    }
  }
</style>
